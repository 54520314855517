export const emailValidation=[
    (v) => !!v || "El email es requerido",
    v =>v && /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email debe de ser valido',
  ];

export const checkValidation=[v => !!v || 'Debes de aceptar los terminos para continuar'];

export const  nombreValidation=[
    (v) => !!v || "El nombre es requerido",
    (v) => v && v.length >= 3  || "El nombre no debe ser menor a 3 ",
    (v) => v && v.length <= 30  || "El nombre no debe ser mayor a 30 ",
  ];
  
export const  apellidosValidation=[
    (v) => !!v || "Los apellidos son requeridos",
    (v) => v && v.length >= 3  || "Los apellidos no pueden ser menor a 3 ",
    (v) => v && v.length <= 40  || "Los apellidos no pueden ser mayor a 40",
  ];

  export const  telefonoValidation=[
    (v) => !!v || "El telefono es requerido",
    (v) => v && v.length == 10 || "El telefono debe ser de 10 digitos",
  ];

  export const  giroValidation=[
    (v) => !!v || "El giro es requerido",
    (v) => v && v.length > 4 || "El giro no puede ser mayor a 4",
  ];
  export const  ubicacionValidation=[
    (v) => !!v || "La ubicacion es requerida",
    (v) => v && v.length > 9 || "Porfavor ingrese mas detalles de la ubicacion",
  ];

  
  
