<template>
     <v-row justify="center">
    <v-dialog
       :value="dialog"
      @input="setDialog"
      width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">¿Aceptas los Terminos y Servicios?</span>
        </v-card-title>
        <v-card-text>
            {{terminos}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
             <v-btn
            color="green darken-1"
            text
            @click="accept"
          >
            Acepto
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="denied"
          >
            Cancelar
          </v-btn>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {

    methods: {
        accept(){
            this.setCheckbox(true);
            this.setDialog();
        },
        denied(){
            this.setCheckbox(false);
        this.setDialog();
        }
    },
    props:[
        'dialog','setDialog','setCheckbox'
    ],
    data: () =>({
        
 
        terminos:`        Términos y condiciones de uso del sitio Web y App “YIPI”
1. Objeto.
TEAM GRILLOS ECO CAR´S, S.A. DE C.V. (en lo sucesivo “YIPI”), con domicilio en Circuito Prados del Valle No. 1145, Col. Campos Eliseos, código postal 47684, Tepatitlan de Morelos, Jalisco, a través de su Aplicación Tecnológica (en adelante “App” y/o “Aplicación”), promueve, promociona y ofrece el servicio de reservación de transporte privado (en lo sucesivo el “Servicio”), lo que permite mejorar la eficiencia de transporte para el Usuario en diferentes zonas de la Ciudad de Guadalajara y zona metropolitana, asi como del Estado de Jalisco.

Al descargar y utilizar la App y/o sitio web www.yipi.mx (en adelante el “Sitio”), el Usuario reconoce que ha leído, entendido y aceptado en todas y cada una de su partes, los presentes términos y condiciones, los cuales, se compromete a cumplir y observar. YIPI se reserva el derecho de modificar en cualquier momento los presentes Términos y Condiciones, limitándose a informar al Usuario indistintamente a través de la pagina web o a través de la aplicación, (App) sobre dicha modificación, por lo que el Usuario deberá acceder al sitio web y/o a la App y en el apartado “Términos y Condiciones”, consultar la actualización de los mismos. Las modificaciones serán efectivas una vez publicadas en la App y el Sitio, el acceso y/o uso del Servicio después de dicha publicación constituye el consentimiento del Usuario a las mismas.

En caso de que el Usuario no esté de acuerdo con los presentes términos y condiciones, y sus modificaciones, deberá abstenerse de utilizar el Sitio y la Aplicación.

2.- Servicios.
YIPI a través de sus Sitio y/o su Aplicación proporciona a los Usuarios una herramienta que permite la utilización de los servicios de transporte privado.

3.- Autorización
Para el acceso y uso del sitio y de la Aplicación, YIPI autoriza a los Usuarios, la descarga, uso, visualización y almacenaje de contenidos y/o elementos insertados en la App y enel Sitio, única y exclusivamente para su uso personal, privado y no lucrativo.

El Usuario reconoce y acepta que YIPI es titular de todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o cualesquiera otros elementos insertados en la App y en el Sitio (marcas, nombres y avisos comerciales, imágenes, textos, gráficos, diseños, audio, video, software, diagramas, etc.). Por tanto, queda prohibido a los prestadores del servicio, conductores y Usuarios: (i) modificar, alterar y/o retirar cualquier contenido, información y/o material incluido en la página web y/o en la App; (ii) acceder o intentar obtener un acceso no autorizado o dañar cualquier aspecto del Sitio, la Aplicación, los Servicios o sus sistemas o redes relacionados; (iii) utilizar el Sitio y/o la App con fines ilícitos, ilegales, y contrarios a lo establecido en los presentes Términos y Condiciones, perjudiciales a los derechos e intereses de terceros; (iv) sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios, del Sitio y de la Aplicación; y (v) introducir o difundir virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en los sistemas de YIPI o de terceros,

El contenido, información y material proporcionado por YIPI en la página web y en la App, está relacionado a la oferta y al uso del servicio privado de transporte, por tanto, YIPI se reserva el derecho a modificar, en cualquier momento su presentación, contenido y configuración. Asimismo, YIPI se reserva el derecho de suspender, interrumpir o dejar de operar el sitio web y la App.

4.- Acceso y Uso de la App.
a) Descarga y Creación de Cuenta.

El Usuario únicamente podrá acceder al Sitio y a la App a través de los medios autorizados. YIPI no será responsable en caso de que el Usuario no disponga de un dispositivo compatible o haya descargado una versión de la Aplicación incompatible con su dispositivo.

Para acceder y utilizar la App, el Usuario deberá descargarla del sitio comercialmente conocidos como “App Store“ y “Google Play”. Una vez descargada la Aplicación, el Usuario deberá crear y mantener una cuenta activa (en adelante “Cuenta”). Para obtener una cuenta, el Usuario deberá tener la edad mínima de 18 años y deberá proporcionar la siguiente información personal: nombre, número de teléfono móvil, correo electrónico, o cuenta de Facebook, e indicar (por lo menos) un método de pago válido (tarjeta de crédito, o abonando saldo a través de tarjeta de crédito o débito), suministrando los datos relacionados con dicho método de pago. Será obligación del Usuario proporcionar y mantener la información en su Cuenta de forma veraz, exacta, completa y actualizada.

Asimismo, será obligación y responsabilidad del Usuario leer las políticas de privacidad y los presentes términos y condiciones, pues durante el proceso de creación de la Cuenta deberá aceptar las mismas.

b) Uso.

Una vez finalizada la creación de la Cuenta, el Usuario podrá hacer uso de la App y realizar la solicitud de su viaje, para ello, deberá introducir en el apartado correspondiente de la App su origen y su destino, ya sea, escribiendo la dirección o ubicándola en el mapa que la propia App despliegue. El mapa muestra los límites del área de Servicio y, si la App arroja YIPIS disponibles para la solicitud del Usuario, le indicara las placas del vehiculo, el nombre del conductor y la tarifa correspondiente.

Teniendo a la vista la información proporcionada por YIPI y siguiendo las instrucciones indicadas por la App, el Usuario podrá solicitar su viaje, generar su pase de abordar, mediante la generación de un código “QR”, o de la forma como disponga YIPI, mismo que deberá mostrar al conductor al abordar la Unidad vehicular en el lugar indicado por la App.

Los pagos del Servicio, podrán efectuarse (i) a través de tarjeta de crédito, o bien, (ii) mediante abono de saldo a la Cuenta del Usuario, utilizando tarjeta de crédito ó débito. El monto mínimo que el Usuario podrá abonar a su Cuenta es de $30.00 (treinta pesos 00/100 Moneda Nacional), mientras que la cantidad máxima será de $500.00 ( quinientos pesos 00/100 Moneda Nacional).

c) Contraseña.

Cada Cuenta será protegida por una contraseña dada de alta por el Usuario al momento de crear la Cuenta, misma que será requerida cada que el Usuario inicie una sesión en la App. El Usuario será responsable en todo momento del resguardo y conservación de su contraseña; por consecuencia, asumirá cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la revelación o extravío de la misma.

En caso de olvido de la contraseña, el Usuario podrá dar de alta una nueva, ingresando al apartado “olvidé mi contraseña” y deberá seguir las instrucciones que se indiquen.

d) Acceso a la Red / Dispositivos.

El Usuario es responsable de obtener el acceso a la red de datos necesario para utilizar los servicios ofrecidos por la App, en el entendido de que deberá de pagar las tarifas y tasas de datos y mensajes de su red móvil, si accede o utiliza el Servicio desde un dispositivo inalámbrico. Asimismo, el Usuario es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar el Sitio y Aplicaciones y/o cualquier actualización de estos. YIPI no garantiza que la App y el Sitio, funcionen correctamente en cualquier hardware o dispositivo particular.

El Usuario reconoce que, debido a la funcionalidad y velocidad de la conexión a Internet y de las comunicaciones electrónicas, el Servicio podrá ser objeto de disfunciones o retrasos, sin que ello implique responsabilidad para YIPI.

e) Requisitos y Conducta del Usuario.

El Usuario se obliga a hacer un uso adecuado y lícito de la App y el Sitio, de conformidad con las leyes aplicables y los presentes términos y condiciones. El acceso a la App y a los Servicios está limitado a personas mayores de 18 años. El Usuario no podrá ceder o transferir de algún modo su Cuenta, ni autorizar a terceros a utilizarla, tampoco podrá permitir que personas menores de 18 años reciba el servicio de transporte, a menos que aquellos sean acompañados por el Usuario titular de la Cuenta.

En el uso del servicio privado de transporte, no causará molestias, incomodidades o daños a la propiedad. En algunos casos, se le podrá requerir que facilite algún documento de identidad para el acceso o uso del servicio de transporte, por tanto, el Usuario acepta que se le podrá negar el acceso o uso de dicho servicio si se rehúsa a facilitar al conductor el documento de identidad.

El Usuario reconoce y acepta que el acceso y uso de la Aplicación y el Sitio son bajo su exclusiva responsabilidad. En ningún caso, YIPI será responsable del uso que el Usuario y/o terceros pudieran hacer de la Aplicación y el Sitio, ni de los daños y perjuicios que pudieran derivarse de ello.

f) Contenido proporcionado por el Usuario.

YIPI podrá permitir que el Usuario califique, envíe, publique o de otro modo ponga en la App y/o en el Sitio, reseñas u opiniones, relativos a los Servicios (en adelante “Opiniones”). Todas las Opiniones serán propiedad y responsabilidad de su autor, sin que ello implique alguna responsabilidad de cualquier naturaleza, a cargo de YIPI.

El Usuario se obliga a no proporcionar Opiniones que tengan contenido ofensivo, difamatorio, calumnioso, obsceno, comprenda violencia, amenazas y/o pueda tener el carácter de ilícito. YIPI, en cualquier momento, podrá eliminar las Opiniones, si considera que el actualiza las prohibiciones contenidas en el presente párrafo.

g) Mensaje de texto, llamadas y notificaciones.

Al crear una Cuenta, el Usuario acepta que para la prestación de los Servicios YIPI le realice llamadas telefónicas, envíe mensajes de texto informativos (SMS), correos electrónicos, notificaciones o mensajes instantáneos por internet como parte de su actividad comercial normal. El Usuario podrá solicitar la no recepción de mensajes de texto informativos (SMS), llamadas, notificaciones o mensajes instantáneos por parte de YIPI, para ello deberá proporcionar el número de teléfono del dispositivo móvil que recibe los mensajes y el correo electrónico registrado en su Cuenta. El Usuario reconoce que solicitar la no recepción de mensajes podrá afectar el uso y recepción de los Servicios.

5.- Pago y Facturación.
El Usuario conoce y acepta que el uso del servicio de reservación y de transporte privado le generan costos (en lo sucesivo “Tarifas”), mismos que serán cubiertos inmediatamente a YIPI, a través de los medios de pago (tarjetas de crédito, o abonando saldo a través de tarjeta, ó pago en tiendas de convenio) dados de alta por el Usuario en su Cuenta.

El pago de Tarifas realizado por el Usuario en la App, incluirán los impuestos aplicables y cubrirá el servicio de transporte.

Las Tarifas cobradas por YIPI son definitivas y no serán reembolsables, al menos que YIPI determine lo contrario, derivado de un defecto en los Servicios no imputable al Usuario. Las tarifas están sujetas a modificaciones, sus actualizaciones estarán disponibles en todo momento en la Aplicación y el Usuario podrá consultarlas antes de reservar y pagar su viaje.

El pago de Tarifas podrá realizarse de forma total o parcial, a través del canje de códigos promocionales, los cuales estarán sujetos a las condiciones adicionales que YIPI establezca sobre la base de cada código promocional y serán comunicados al Usuario en el Sitio y en la App.

Por motivos de seguridad, YIPI podrá bloquear, cancelar o anular cualquier método de pago cuando existan sospechas razonables sobre un uso indebido del mismo. YIPI se reserva el derecho a modificar, ampliar, reducir, establecer y/o eliminar cualquier método de pago en cualquier momento, según lo estime conveniente para el correcto funcionamiento del servicio.

6.- Códigos Promocionales.
YIPI podrá crear códigos promocionales que podrán ser canjeados por descuentos u otros elementos o beneficios relacionados con los servicios. Para hacer efectivos el canje del beneficio, el Usuario deberá sujetarse a las condiciones adicionales que YIPI establezca sobre la base de cada código promocional, las cuales, le serán comunicados enel Sitio y/o en la App (en lo sucesivo “Condiciones Adicionales”).

Para el uso y/o canje de los Códigos Promocionales, el Usuario conoce y acepta que: (i) deberá observar y cumplir con lo dispuesto en las condiciones adicionales establecidas por YIPI para dicho Código Promocional; (ii) podrán ser anulados por YIPI en cualquier momento y por cualquier motivo, sin responsabilidad alguna para ésta; (iii) no podrán canjearse por dinero en efectivo; y (iv) una vez caducada su vigencia no podrán canjearse y/o hacerse efectivo el crédito y/o el beneficio.

YIPI se reserva el derecho de disminuir y/o anular el crédito u otros elementos o beneficios obtenidos a través de la utilización de los Códigos Promocionales, cuando considere o determine que el uso o el canje de éstos fue realizado de modo erróneo, fraudulento, ilegal o en contravención a lo dispuesto en los presentes términos y condiciones y/o en las condiciones adicionales.

7.- Datos Personales.
Los datos personales que el Usuario proporcione al crear su Cuenta y al proporcionar los Métodos de Pago serán utilizados únicamente para la prestación de los servicios y conforme al Aviso de Privacidad disponible en la App y el Sitio.

8.- Suspensión o Cancelación del Servicio.
YIPI podrá suspender o bloquear la Cuenta del Usuario y/o cancelar cualquier Servicio, en cualquier momento y sin previo aviso, en caso de que se actualice alguno de los siguientes supuestos:

a) Si el Usuario proporciona información falsa o fraudulenta en su Cuenta;

b) Si el Usuario al utilizar la App y/o el Sitio proporciona Contenido de Usuario de carácter ofensivo, discriminatorio, difamatorio, calumnioso, obsceno, pornográfico, comprenda violencia, odio, amenazas y/o pueda tener el carácter de ilícito.

c) Si el Usuario ocasiona algún daño a las unidades vehiculares que prestan el servicio contratado, o bien cometa algún acto ilícito a bordo de dichas unidades.

d) Si el Usuario utiliza el Sitio y/o la App con fines ilícitos, ilegales, perjudiciales a los derechos e intereses de YIPI y/o de terceros.

e) Cualquier otro caso que implique una violación por parte del Usuario a los presentes Términos y Condiciones.

9.- Responsabilidad.
En relación a la App y el Sitio

YIPI no será responsable por cualesquiera daños y perjuicios que puedan derivarse de: (i) la falta de accesibilidad o disponibilidad a la Aplicación y/o al Sitio Web, o bien, la interrupción en el funcionamiento de éstos, originada por fallos informáticos, desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas telefónicas, en el sistema de Internet o en otros sistemas electrónicos; (ii) la presencia de virus informáticos que puedan producir alteraciones a los dispositivos informáticos del Usuario y; (iii) otros daños que puedan ser causados por terceros a través de acciones no autorizadas y/o ajenas al control de YIPI.

En relación al Servicio Privado de Transporte

YIPI se libera de toda responsabilidad, cuando los daños causados sean por caso fortuito o causa de fuerza mayor.

10. Notificaciones
YIPI podrá efectuar notificaciones al Usuario, a través de la Aplicación, mediante correo electrónico y/o SMS. Por su parte, el Usuario a su vez podrá realizar notificaciones a YIPI a través del correo electrónico contacto@yipi.mx

11. Contacto para consultas, aclaraciones o reclamaciones.
En caso de dudas, aclaraciones y/o reclamaciones respecto al Sitio, el funcionamiento de App y de los Servicios contratados a través de la App, YIPI pone a disposición del Usuario el siguiente correo electrónico contacto@yipi.mx, el número telefónico (378) 781 80 29, o bien, la pestaña de la App denominada “Soporte”.

En caso de reclamaciones, YIPI levantará un reporte, dará seguimiento a la misma y se pondrá en contacto con el Usuario, dentro de las 48 horas siguientes, para: (i) solicitar mayor información, bien, (ii) informarle sobre la procedencia o improcedencia de la misma. En caso de ser procedente, YIPI procederá a reembolsar a su Cuenta una parte del viaje, o bien su totalidad.

12. Ley aplicable y jurisdicción.
Los presentes Términos y Condiciones, así como la relación entre YIPI y el Usuario, se regirán e interpretarán con arreglo las leyes de los Estados Unidos Mexicanos. Para la interpretación o controversia que se suscite sobre la interpretación y cumplimiento de los mismos, la Procuraduría Federal de Protección al Consumidor (“PROFECO”) será competente, en la vía administrativa, para conocer y resolver.`
    })
}
</script>
